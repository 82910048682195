import React, {useMemo, useState} from "react";
import './Input.scss';
import {Label, Message} from "semantic-ui-react";
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import _ from "lodash";
import {parseFollowupConditions, parseFollowupReasons, renderFollowup, checkNumeric, inputBlur} from "../FieldsUtils";

const Input = ({settings, handleChange, order, index, followupValue, subtype, defaultValue = ""}) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState("");
  const followup_conditions = useMemo(() => parseFollowupConditions(settings), [settings]);
  const [followupValues, setFollowupValues] = useState({radio: followupValue.radio, text: followupValue.text});
  const followup_reasons = useMemo(() => parseFollowupReasons(settings), [settings]);

  const label = settings.get('question_text');
  const subtitle = settings.get('question_subtitle');

  const _subtype = ((typeof subtype !== "undefined") && (subtype === "1")) ? "number" : "text";

  const handleInputChange = (event) => {
    event.preventDefault();
    const _value = event.target.value;
    let hasError = "";
    if ((_subtype !== 'number')) {
      hasError = (!_.isEmpty(_value) && !isAlphanumeric(_value, 'en-US', {ignore: " .,;:@_/$&"}))
        ? "Invalid value"
        : "";
      setError(hasError);
    }
    //else {
      inputBlur(event, setError);
    //}
    setValue(_value);
    handleChange(index, _value, "", 'input', hasError);
  };

  const handleFollowupChange = (event, newValue, type) => {
    event.preventDefault();
    const _value = followupValues;
    _value[type] = newValue;
    setFollowupValues(_value);
    handleChange(index, value, _value, type);
  }

  return (<React.Fragment>
    <div className='questions-input'>
      <Label>{order}. {label}</Label>
      {subtitle && <p className="input-subtitle">{subtitle}</p>}
      {(_subtype === 'text') &&
        <input type={_subtype} inputMode="text" className='input-field-input' value={value} onChange={handleInputChange}
               pattern="^(?:[\w\s,\.\$\:\,\@\!\_]*)$"
        />
      }
      {(_subtype === 'number') &&
        <input type="text" inputMode="text" className='input-field-input' value={value} onChange={handleInputChange}
               min='0.00'
               pattern="^(?:[\$\(\)%,\.\+-\d]*\d+)$"
               onKeyDown={checkNumeric}
               onBlur={(e) => inputBlur(e, setError)}
        />
      }
      {renderFollowup(settings, 'any', followup_conditions, followup_reasons, followupValues.radio, followupValues.text, order, handleFollowupChange)}
      {(error.length > 0) && <Message
        error
        list={[error]}
      />}
    </div>
  </React.Fragment>);
};

export default Input;